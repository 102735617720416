.App {
  text-align: center;
}
.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}
.product{
  padding: 20px;
  background: #fff;
  box-shadow: 0px 2px 2px 2px #cccccc54;
  margin: 20px auto;
  width: 90%;
  border-radius: 5px;
}
.productList{
  width: 100%;
}
.productList thead{
  background: #edecd0;
  color: #000;
}
.productList thead th{padding: 10px;border-spacing: 0;border: 1px solid #ccc;}
.header{
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 20px 30px 20px 30px;
    height: 70px;
    box-shadow: 0px 2px 2px 2px #cccccc54;
}
.header img{
    width: auto;
    height: 100%;
}
::-webkit-scrollbar {
  width: 0.5em;
  height: 0.5em;
}
::-webkit-scrollbar-thumb {
  background: #777;
}
::-webkit-scrollbar-track {
  background: #d6d6d6;
}
table {
  border-spacing: 0;
  border-collapse: collapse;
}
.description {
  z-index: 10;
  overflow: hidden;
  text-transform: uppercase;
  margin: 0px 0 0px;
  position: relative;
  text-align: center;
  color: #FFF;
  font-weight: bold;
}
.ribbon {
  font-size: 14px !important;
  width: 90%;
  position: relative;
  background: #008000;
  color: #fff;
  text-align: center;
  padding: 10px 10px;
  margin: 10px auto 20px;
  letter-spacing: 0.5px;
  text-transform: initial;
  line-height: 20px;
}
.ribbon .ribbon-content{
    font-weight: 400;
    line-height: 20px;
}
.ribbon:before, .ribbon:after {
  content: "";
  position: absolute;
  display: block;
  bottom: -16px;
  border: 16px solid #256e25;
  z-index: -1;
}
.ribbon:before {
  left: -24px;
  border-right-width: 24px;
  border-left-color: transparent;
}
.ribbon:after {
  right: -24px;
  border-left-width: 24px;
  border-right-color: transparent;
}
.ribbon .ribbon-content:before, .ribbon .ribbon-content:after {
  content: "";
  position: absolute;
  display: block;
  border-style: solid;
  border-color: #094809 transparent transparent transparent;
  bottom: -16px;
}
.ribbon .ribbon-content:before {
  left: 0;
  border-width: 16px 0 0 16px;
}
.ribbon .ribbon-content:after {
  right: 0;
  border-width: 16px 16px 0 0;
}
.grouped_total {
  overflow: hidden;
  text-transform: uppercase;
  margin: 0px 0 0px;
  box-shadow: 0px 2px 2px #ccc;
  position: relative;
  text-align: center;
  color: #FFF;
  font-weight: 600;
  position: sticky;
  -webkit-position: sticky;
  top: 0;
}
#quick-shop .grouped_total .totel-box {
  width: 25%;
  margin-left: 0;
  float: left;
  min-height: 55px;
  padding: 15px 0;
}
#quick-shop .nop {
  background: #ff9203;
  margin-left: -28px;
  z-index: 0;
}
.grouped_total .totel-box .nop-bor {
  display: block;
  width: 90px;
  height: 1px;
  background: rgba(0,0,0,0.3);
  margin: 7px auto 0;
}
.grouped_total .totel-box .nop-bor-bot {
  display: block;
  width: 90px;
  height: 1px;
  background: rgba(255, 255, 255, 0.3);
  margin: 0 auto 7px;
}
.grouped_total .totel-box p {
  font-size: 18px;
  margin: 0;
}
#quick-shop .noi {
  background: #004394;
  margin-left: -28px;
  z-index: 0;
}
#quick-shop .ta {
  background: #00988d;
  margin-left: -28px;
  z-index: 0;
}
#quick-shop .ys {
  background: #e50b6f;
  margin-left: -28px;
  z-index: 0;
}
.center{text-align: center !important;}
.left{text-align: left !important;}
.right{text-align: right !important;}
table tbody tr td{
  height: 50px;
  padding: 5px 10px;
  border: 1px solid #efefef;
}
table tbody tr img{width: auto;max-height: 50px;max-width: 50px;}
.productList .prod-name{
  min-width: 100px;
}
.productList tbody .prod-name{font-size: 14px;color: #444;font-weight: 500;line-height: 22px;}
.productList .prod-price{
  color: #c71b22;
  font-size: 16px;
  font-weight: bold;
  min-width: 55px;
}
.productList .prod-price p{margin: 0;}
.productList .prod-price span{
  color: #999;
  font-size: 12px;
  font-weight: 600;
}
.productList .qtyCount{
  display: inline-flex;
  align-items: center;
  justify-content: center;
  border: 1px solid #ddd;
  border-radius: 3px;
}
.productList .qtyCount .form-control{
  width: 40px;
  text-align: center;
  outline: none;
  font-size: 13px;
  border: none;
  line-height: 20px;
  padding: 5px;
  font-family: 'Inter';
  font-weight: 600;
}
.productList .qtyCount button.button{background: #fff;outline: none;border: none;font-size: 16px;font-weight: 600;}
.inputCtrl{
  text-align: left;
  outline: none;
  font-size: 13px;
  line-height: 20px;
  border: 1px solid #ddd;
  padding: 5px;
  border-radius: 3px;
  font-family: 'Inter', sans-serif;;
  font-weight: 600;
}
.product .contactForm{
  padding: 20px 0;width: 60%;
}
label{font-size: 14px;color: #444;font-weight: 500;}
label span{color: red;}
.product .contactForm .form-group{
  display: inline-flex;
  flex-direction: column;
  width: 45%;
  padding: 10px;
  font-size: 14px;
  font-family: 'Inter', sans-serif;;
}
.product .contactForm .field{padding: 10px;}
input[type="checkbox"]{outline: none;}
.product .contactForm .field p,
.form-qr-section .qrcode-section .term-section p{
  font-size: 12px;
  color: rgb(102, 102, 102);
  line-height: 16px;
  text-align: justify;
  font-weight: 500;
}
.sendBtn{
  text-transform: uppercase;
  padding: 10px 20px;
  background: #d92b81;
  color: #fff;
  outline: none;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-weight: 600;
  font-family: 'Inter', sans-serif;;
  letter-spacing: .5px;
}
.responsive-table{overflow-x: auto;}
.visible-xs{display: none !important;}
.hidden-xs{display: block !important;}
table tbody tr td .inputCtrl{width: 100px;}
input[type="number"].hideControls {
  -moz-appearance: textfield;
}
input[type="number"].hideControls::-webkit-inner-spin-button, 
input[type="number"].hideControls::-webkit-outer-spin-button { 
  -webkit-appearance: none; 
  margin: 0; 
}
.product .contactForm .field .checkbox{
    display: flex;
    align-items: center;
    gap: 5px;
}
.footer{
  color: #353535;
  background: #f4f4f4;
  margin: 0;
  padding: 30px 10px;
  font-size: 13px;
  text-align: center;
  font-family: 'Inter', sans-serif;
  font-weight: 500;
  line-height: 20px;
}
.footer a{
  text-decoration: none;
  color: #e20681;
}

.contactForm .button--loading{
  position: relative;
}

.contactForm .button__text {
  color: #ffffff;
  transition: all 0.2s;
}

.contactForm .button--loading .button__text {
  visibility: hidden;
  opacity: 0;
}

.contactForm .button--loading::after {
  content: "";
  position: absolute;
  width: 16px;
  height: 16px;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  border: 4px solid transparent;
  border-top-color: #ffffff;
  border-radius: 50%;
  animation: button-loading-spinner 1s ease infinite;
}

@keyframes button-loading-spinner {
  from {
    transform: rotate(0turn);
  }

  to {
    transform: rotate(1turn);
  }
}

.mainApp .popup {
  position: fixed;
  top: 0;
  left: 0;
  margin: auto;
  width: 100%;
  z-index: 99;
}
.mainApp .popup .popup-inner{  
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px;
  background: #f04d6a;
  color: #fff;
}
.mainApp .popup .popup-inner.success-popup{
  background-color: #28a745;
}
.mainApp .popup .popup-inner.danger-popup{
  background-color: #dc3545;
}
.mainApp .popup .close {
  font-size: 22px;
  font-weight: bold;
  text-decoration: none;
  color: #fff;
  cursor: pointer;
  background: transparent;
  border: none;
}
.mainApp .popup .close:hover {
  color: #333;
}
.mainApp .popup .content {
  max-height: 30%;
  overflow: auto;
}

.contactForm span.text-danger{
  color: red;
  font-size: 12px;
  margin-top: 3px;
}
.form-qr-section {
  display: flex;
  flex-wrap: wrap;
}
.form-qr-section .qrcode-section{
  width: 40%;
  padding: 20px 0;
}
.form-qr-section .qrcode-section .term-section{
  padding: 10px;
  padding-bottom: 0
}
.form-qr-section .qrcode-section .term-section h6{
  font-size: 16px;
  margin: 0;
  color: #353535;
}
.form-qr-section .qrcode-section .qr-section .img-box{ 
  margin-top: 10px;
}
.form-qr-section .qrcode-section .qr-section .img-box img{  
  width: 250px;
  height: auto;
  max-height: 100%;
  max-width: 100%;

}
@media(min-width: 767px){
  .header .contact-width{
    min-width: 200px;
  }
  #quick-shop .grouped_total.save-total-not .totel-box {
    width: 33.33%;
    transition: all 0.2s ease-in-out;
  }
}
@media(max-width:767px)
{
  .header {
    padding: 10px;
    height: auto;
    flex-direction: column-reverse;
  }
  .visible-xs{display: block !important;}
  .hidden-xs{display: none !important;}
  .header p{margin-bottom: 0;width: 100%;text-align: right;font-size: 13px;font-weight: 500;}
  .ribbon {
    font-size: 13px !important;
    width: 80%;
  }
  #quick-shop .grouped_total .totel-box {
    width: 33.333%;
  }
  .grouped_total{margin: 15px 0;display: flex;}
  .product {
    padding: 10px;
    margin: 10px auto;
    width: 85%;
  }
  .grouped_total .nop-name{font-size: 13px;}
  .grouped_total .totel-box p{font-size: 14px;}
  .product .contactForm,
  .form-qr-section .qrcode-section{
    padding: 10px 0px;
    width: 100%;
  }
  .product .contactForm .form-group{width: 90%;}
  .productList thead th{font-size: 13px;}
  #quick-shop .chk {
    background: #65c178;
    padding-top: 15px !important;
    display: flex !important;
    align-items: center;
    justify-content: center;
  }
  #quick-shop .cls1 {
    background: #f0941f !important;
    border: none;
    padding: 0px 10px;
    font-size: 15px;
    font-weight: Bold;
    line-height: 34px;
    text-align: center;
    white-space: nowrap;
    border-radius: 3px;
    color: #fff;
    text-transform: capitalize;
    opacity: 0.5;
    font-family: 'Inter', sans-serif;;
  }
  
  #quick-shop .grouped_total .totel-box {
    width: 50%;
}
table tbody tr td .inputCtrl {
  width: 60px;
}
.tableBody .tableItem{
  display: flex;
  align-items: center;
  gap: 5px;
  padding: 10px 0;
  border-bottom: 1px solid #ddd;
}
.tableBody .imageSec{width: 20%;padding: 5px;}
.tableBody .imageSec img{width: auto;max-height: 50px;max-width: 50px;}
.tableBody .contentSec{width: 80%;}
.tableBody .contentSec .prod-name {
  font-size: 14px;
  color: #444;
  font-weight: 500;
  line-height: 22px;
  margin-bottom: 5px;
}
.tableBody .contentSec .prodRate{display: flex;align-items: center;justify-content: space-between;gap: 5px;}
.tableBody .contentSec .prodRate .prod-price {
  color: #c71b22;
  font-size: 16px;
  font-weight: bold;
  min-width: 55px;
}
.tableBody .contentSec .prodRate .prod-price p{margin: 0;}
.tableBody .contentSec .prodRate .prod-price span {
  color: #999;
  font-size: 12px;
  font-weight: 600;
}
.tableBody .contentSec .prodRate .qtyCount {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  border-radius: 3px;
  border: 1px solid #ddd;
}
.tableBody .contentSec .prodRate .qtyCount .form-control {
  width: 40px;
  text-align: center;
  outline: none;
  font-size: 13px;
  border: none;
  line-height: 20px;
  padding: 5px;
  font-family: 'Inter';
  font-weight: 600;
}
.tableBody .contentSec .prodRate .inputCtrl {
  width: 70px;border: none;
}
.tableBody .contentSec .prodRate .subTotal{
  display: flex;align-items: center;justify-content: center;padding-left: 20px;
}

.header img{
  height: 60px;
}

}